#root .navbar_iconcontainer{
  display: flex;
  box-sizing: border-box;
  height: 3rem;
  width: 3rem;
  padding: 0;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
}

#root .navbar_iconcontainer_floating{
  position: absolute;
  display: flex;
  height: 3rem;
  width: 3rem;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: 2px;
  border-radius: 50px;
  background-color: blueviolet;
  transition: width 0.5s, border-radius 0.5s,  border 0.5s;
  left: 10px;

}
#root .navbar_iconcontainer_floating.active{
  width: 22rem;
  border-bottom-left-radius: 0;
  position:absolute;
  left: 10px;
  z-index: 1;

}

#root .navbar_icontext{
  display: none;
  color: transparent;
  width: 0px;
  transition: width 1s color 1s;
  &.active{
    display: inline;
    margin-left: 1rem;
    color: black;
    width:max-content;
  }

}



body{
  margin: 0;
  padding: 0;
  background-color: black;
}
.App{
  display:flex;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: fit-content;
  font-family: Arial, Helvetica, sans-serif;
}

.header{
  flex: 30%;
  background-color: tomato;
  width: 100%;
  height: fit-content !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header .inputForm_title{
  font-size: 2rem;
  font-weight: bold;
}
.header .inputForm{
  display: flex;
  flex-direction: row;
}

.header .inputContainer{
  display:flex;
  flex-direction: column;
}

.header input{
  width: 200px;
  height: 40px;
  border: none;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  padding-left: 10px;
  font-size: 17px;
  border: 1px solid grey;
}

.header .appbtn{
  width: 70px;
  height: 160px;
  border: none;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  padding-left: 10px;
  cursor: pointer;
}
.header .prioritysel {
  width: 200px;
  height: 40px;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  padding-left: 10px;
  font-size: 17px;
}

.allLists{
  display: flex;
  flex: 70%;
  flex-direction: row;
}

.Droppable{
  padding: 20px;
  min-height: 100%;
}
.draggable{
  height: 115px;
}
.todoList{
  flex: 30%;
  min-width: 300px;
  width:90%;
  height: min-content;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
  flex-direction: column;
  background-color: lightblue;
  border: 9px solid black;

  & .todoList-priority{
    font-size: 2rem;
    font-weight: bold;
  }
}
@media only screen and (max-width: 1016px){
  .allLists{
    flex-direction: column;
  }
  .todoList{
    width:100%;
  }
}



.task{
  width:90%;
  display: flex;
  color: black;
  margin: 15px;

  & .content{
    align-items: center;
    height: fit-content;
    background-color: grey;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;

    & *{
      font-size: 1rem;
      text-align: center;
      justify-content:center;
      align-items: center;
      padding-bottom: 5px;
      line-height: 1.1;
      padding: .1rem;
      
    }
    & .col{
      min-width: 3rem;
    }
    & .task_title{
      color: white;
      height: 40px;
      padding-top: 6px;
      font-size: 1.5rem;
      font-weight: bold;
      font-family: Arial, Helvetica, sans-serif;
    }
    
  }
  & .content.content-overdue{
      background-color: tomato;
  }
  & .appbtn{
    flex: 10%;
    height: 110px;
    border-bottom-right-radius: 1rem;
    border-top-right-radius: 1rem;
    border-right: 1px solid black;
    border-left: 1px solid black;
    color: gainsboro;
    background-color: gray;
    cursor: pointer;
  }
  & .tasklinebreak{
    display: flex;
    flex: 100%;
    height: 0;
    padding: 0;
    margin:0;
    width: 100%;
  }

}

.devstate{
  display: block;
  position:fixed;
  bottom: 0;
  right:0;
  color: white;
}